import React from 'react'
import Layout from '../components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

class Kontakt extends React.Component {

    state = {
        showInfo: false
    }

    showMarkerInfo = () => {
        this.setState({
            showInfo: true
        })
    }

    hideMarkerInfo = () => {
        this.setState({
            showInfo: false
        })
    }

    render() {

        return (
            <Layout>
                <section id="contact" className="section-wrapper">
                    <header className="section-header">
                        <h3>Kontakt</h3>
                    </header>
                    <div className="row contact-info">
                        <div className="col-md-4">
                            <div className="contact-address">
                                <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" color="#9acf47" />
                                <h3>Adresse</h3>
                                <p><a href="https://www.google.ch/maps/place/Pilatusstrasse+39,+6003+Luzern/@47.0478693,8.3021381,17z/data=!3m1!4b1!4m6!3m5!1s0x478ffba1a5c57c4f:0xcdf5e9a40f7479ce!8m2!3d47.0478693!4d8.304713!16s%2Fg%2F11c5b6c1kt?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D">LEHMANN+PARTNER Informatik AG, Pilatusstrasse 39, CH-6003 Luzern</a></p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-phone">
                                <FontAwesomeIcon icon={faPhone} size="3x" color="#9acf47" />
                                <h3>Telefon</h3>
                                <p><a href="tel:+41412273070">+41 41 227 3070</a></p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-email">
                                <FontAwesomeIcon icon={faEnvelope} size="3x" color="#9acf47" />
                                <h3>Email</h3>
                                <p><a href="mailto:info@lepa.ch">info@lepa.ch</a></p>
                            </div>
                        </div>
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d679.6531166484594!2d8.304725746125984!3d47.047834773442645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ffba22c2886a5%3A0x267a004390564f3f!2sLEHMANN%20%2B%20PARTNER%20Informatik%20AG!5e0!3m2!1sde!2sch!4v1582887130899!5m2!1sde!2sch"
                        width="100%"
                        height="450"
                        frameborder="0"
                        allowfullscreen="" />
                </section>
            </Layout>
        )
    }
}

export default Kontakt;
